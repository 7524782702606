@media only screen and (max-width: 600px) {
    .adjust {
        height: auto !important;
    }

    #root>div>main>div.MuiContainer-root {
        max-width: 100%;
    }
}

@media only screen and (min-width: 1100px) {
    #root>div>main>div.MuiContainer-root {
        max-width: 80%;
    }
}